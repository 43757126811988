const register = {
    state: {
        page: 'home',
        departmentData: [],
        today: null,
        timeData: {},
        registerInfo: {
            patientId: null,
            registerType: null,
            deptCode: null,
            deptName: null,
            appointmentRegisterDate: null,
            appointmentRegisterPeriod: null,
            appointmentRegisterTime: null,
            numType: null,
            numSourceId: null
        },
        successInfo: {
            seq: "",
            visitorName: "",
            departmentName: "",
            visitTime: "",
            registerSuccessTime: "",
            tipFlag: false,
            tipContent: ""
        }
    },
    mutations: {
        REGISTER_BASE_DATA: (state, data) => {
            state.departmentData = data.departmentData;
            state.today = data.today;
            state.timeData = data.timeData;
        },
        SELECT_REGISTER_TYPE: (state, data) => {
            state.page = data.page;
            state.registerInfo.registerType = data.registerType;
            state.registerInfo.patientId = data.patientId;
        },
        FILL_VISITOR: (state, data) => {
            state.page = data.page;
            state.registerInfo.visitType = data.visitType;
            state.registerInfo.visitorName = data.visitorName;
            state.registerInfo.visitorNumber = data.visitorNumber;
            state.registerInfo.familyName = data.familyName;
            state.registerInfo.familyNumber = data.familyNumber;
            state.registerInfo.mobilePhone = data.mobilePhone;
        },
        SELECT_DEPARTMENT: (state, data) => {
            state.page = data.page;
            state.registerInfo.deptCode = data.deptCode;
            state.registerInfo.deptName = data.deptName;
        },
        SELECT_TIME: (state, data) => {
            state.page = data.page;
            state.registerInfo = data.registerInfo;
            state.successInfo = data.successInfo;
        },
        CHANGE_PAGE: (state, data) => {
            state.page = data;
        },
    },
    actions: {}
};

export default register;